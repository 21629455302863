.overlay {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 22;
}

.modal {
    width: 400px;
    height: 200px;
    background-color: #fff;
    z-index: 22;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
}