.main-page {
  background-image: url("../../assets/gifs/ezgif-4-0832859f00.gif");
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &__inner {
    background: #150029c2;
    width: 100vw;
    height: 100vh;
  }

  .main {
    &__title {
      position: relative;
      // &::before {
      //     content: '';
      //     position: absolute;
      //     width: 600px;
      //     height: 1px;
      //     left: 100px;
      //     top: 50%;
      //     transform: translateY(-50%);
      //     background: rgba(76, 64, 131, 0.5);;
      // }
      // &::after {
      //     content: '';
      //     position: absolute;
      //     width: 600px;
      //     height: 1px;
      //     right: 100px;
      //     top: 50%;
      //     transform: translateY(-50%);
      //     background: rgba(76, 64, 131, 0.5);;
      // }
    }
  }

  .footer__call {
    svg {
      @media screen and (max-width: 640px) {
        width: 16px !important;
      }
    }
  }

  .footer__link {
    svg {
      @media screen and (max-width: 640px) {
        width: 16px !important;
      }
    }
  }
}

.form-wrapper {
  box-shadow: 0px 8px 11px -2px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.035em;
    color: #4c4083;
    margin-bottom: 50px;
  }

  input,
  select,
  textarea {
    outline: none;
  }

  .subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    max-width: 250px;
    color: rgba(22, 22, 22, 0.7);
  }
}
.onHover:hover {
  background-color: #c5bebe;

  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
}
.cloneModal {
  width: 30px;
  height: 30px;
  float: right;
  display: flex;
  align-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  justify-content: center;
}

.lang {
  color: #fff;
}

.href_lang {
  margin: 10px;
  display: flex;
}

.lang_img {
  width: 20px;
  height: 12px;
  margin: 6px;
}
