html {
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  overflow-x: hidden;
  background: #f0f0f0;
}

.error {
  color: red;
  font-size: 12px;
}
